import { Seo } from "@/types/Seo";
import React, { ReactNode } from "react";
import SeoHead from "../atoms/SeoHead";
import Footer from "../organisms/Footer";
import Header from "../organisms/Header";
interface DefaultLayoutProps {
  seo: Seo;
  children: ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ seo, children }) => {
  return (
    <>
      <SeoHead seo={seo} />
      <div className="flex min-h-screen flex-col">
        <Header />
        <main className="flex-1">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default DefaultLayout;
