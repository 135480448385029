import clsx from "clsx";
import { forwardRef } from "react";

const baseStyles: Record<string, any> = {
  solid:
    "inline-flex justify-center rounded-lg py-2 px-3 text-sm font-semibold outline-2 outline-offset-2 transition-colors",
  outline:
    "inline-flex justify-center rounded-lg border py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-sm outline-2 outline-offset-2 transition-colors",
};

const variantStyles: Record<string, any> = {
  solid: {
    primary:
      "relative overflow-hidden bg-primary text-white before:absolute before:inset-0 active:before:bg-transparent hover:before:bg-white/10 active:bg-primary/90 active:text-white/80 before:transition-colors",
    secondary:
      "bg-secondary text-white hover:bg-secondary/90 active:bg-secondary/90 active:text-white/70",
    white:
      "bg-white text-cyan-900 hover:bg-white/90 active:bg-white/90 active:text-cyan-900/70",
    disabled: "bg-gray-200 text-gray-500 cursor-not-allowed",
  },
  outline: {
    primary:
      "border-primary text-gray-900 hover:border-gray-400 active:bg-gray-100 active:text-gray-700/80",
    disabled: "border-gray-200 text-gray-500 cursor-not-allowed",
  },
};

const variantSize: Record<string, any> = {
  sm: "px-2.5 py-1.5 text-xs font-medium",
  md: "px-3 py-2 text-sm font-medium leading-4",
  lg: "px-4 py-2 text-sm font-medium",
  xl: "px-4 py-2 text-base font-medium",
  "2xl": "px-6 py-3 text-base font-medium",
};

export type ButtonProps = {
  variant?: "solid" | "outline";
  color?: "primary" | "secondary" | "white";
  type: "button" | "submit" | "reset" | undefined;
  size?: "sm" | "md" | "lg" | "xl" | "2xl";
  block?: boolean;
  loading?: boolean;
} & JSX.IntrinsicElements["button"];

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "solid",
      color = "primary",
      size = "lg",
      className,
      block = false,
      loading = false,
      ...rest
    },
    ref
  ) => {
    const classes = clsx(
      baseStyles[variant],
      variantStyles[variant][color],
      variantSize[size!],
      className,
      { "w-full": block }
    );

    if (loading) {
      return (
        <div className={classes}>
          <span className="flex animate-pulse space-x-2 py-2">
            <div className="h-2 w-2 rounded-full bg-white" />
            <div className="h-2 w-2 rounded-full bg-white" />
            <div className="h-2 w-2 rounded-full bg-white" />
          </span>
        </div>
      );
    }

    return <button ref={ref} className={classes} {...rest} />;
  }
);

Button.displayName = "Button";

export default Button;
