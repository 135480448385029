import { AdvancedVideo } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";

// Import required actions and qualifiers.
import { RelatedMedia } from "@/types/Media";

interface CloudinaryVideoProps {
  media: RelatedMedia;
}

const CloudinaryVideo: React.FC<CloudinaryVideoProps> = ({ media }) => {
  // Create and configure your Cloudinary instance.
  const cld = new Cloudinary({
    cloud: {
      cloudName: "codealodev",
    },
  });

  // Use the video with public ID, 'docs/walking_talking'.
  const video = cld.video(media.data.attributes.provider_metadata.public_id);
  return (
    <div>
      <AdvancedVideo cldVid={video} controls />
    </div>
  );
};

export default CloudinaryVideo;
