import { Seo } from "@/types/Seo";
import Head from "next/head";

interface SeoProps {
  seo: Seo;
}

const SeoHead: React.FC<SeoProps> = (props) => {
  const { seo } = props;
  const { canonicalUrl } = seo;
  const twitter = seo.socials.find((social) => social.network === "twitter");

  const facebook = seo.socials.find((social) => social.network === "facebook");

  return (
    <Head>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />

      {/* Open Graph tags */}
      {facebook && (
        <>
          <meta property="og:title" content={facebook.title} />
          <meta property="og:description" content={facebook.description} />
          <meta property="og:url" content={seo.canonicalUrl} />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={`${facebook.image.data.attributes.url}`}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:site_name" content="Codealo" />
        </>
      )}

      {/* Twitter card tags */}
      {twitter && (
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={twitter.title} />
          <meta name="twitter:description" content={twitter.description} />
          <meta
            name="twitter:image"
            content={`${twitter.image.data.attributes.url}`}
          />
        </>
      )}

      {/* Canonical URL */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <meta name="robots" content={seo.robots} />
    </Head>
  );
};

export default SeoHead;
