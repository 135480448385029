import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { Feature } from "@/types/Components/Page";
import { getUploadUrl } from "@/utils/uploadUtils";
import { motion } from "framer-motion";
import Image from "next/image";
import CircleBackground from "src/components/atoms/CircleBackground";
import CloudinaryVideo from "src/components/atoms/CloudinaryVideo";
import Container from "src/components/atoms/Container";
import DynamicHeroIcon from "src/components/atoms/DynamicHeroIcon";

const headerAnimation = {
  initial: { opacity: 0, transition: { duration: 0.3 } },
  animate: { opacity: 1, transition: { duration: 0.3, delay: 0.3 } },
  exit: { opacity: 0, transition: { duration: 0.3 } },
};

const maxZIndex = 2147483647;

function usePrevious(value: any) {
  let ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FeatureMedia({ feature }: { feature: Feature }) {
  switch (feature.media.data.attributes.provider_metadata?.resource_type) {
    case "video":
      return <CloudinaryVideo media={feature.media} />;
    default:
      return (
        <Image
          src={getUploadUrl(feature.media.data.attributes)}
          alt={feature.media.data.attributes.alternativeText}
          width="720"
          height={720 / 2}
        />
      );
  }
}

function FeaturesDesktop({ features }: { features: Feature[] }) {
  let [changeCount, setChangeCount] = useState(0);
  let [selectedIndex, setSelectedIndex] = useState(0);
  let prevIndex = usePrevious(selectedIndex);
  let isForwards = prevIndex === undefined ? true : selectedIndex > prevIndex;

  let onChange = useDebouncedCallback(
    (selectedIndex) => {
      setSelectedIndex(selectedIndex);
      setChangeCount((changeCount) => changeCount + 1);
    },
    100,
    { leading: true }
  );

  return (
    <Tab.Group
      as="div"
      className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24"
      selectedIndex={selectedIndex}
      onChange={onChange}
      vertical
    >
      <Tab.List className="relative z-10 order-last col-span-6 space-y-6">
        {features.map((feature: Feature, featureIndex: number) => (
          <div
            key={feature.title}
            className="relative rounded-2xl transition-colors hover:bg-gray-800/30"
          >
            {featureIndex === selectedIndex && (
              <motion.div
                layoutId="activeBackground"
                className="absolute inset-0 bg-gray-800"
                initial={{ borderRadius: 16 }}
              />
            )}
            <div className="relative z-10 p-8">
              <DynamicHeroIcon
                icon={feature.icon}
                className="h-8 w-8 text-white"
              />
              <h3 className="mt-6 text-lg font-semibold text-white">
                <Tab className="text-left [&:not(:focus-visible)]:focus:outline-none">
                  <span className="absolute inset-0 rounded-2xl" />
                  {feature.title}
                </Tab>
              </h3>
              <p className="mt-2 text-sm text-gray-400">
                {feature.description}
              </p>
            </div>
          </div>
        ))}
      </Tab.List>
      <div className="relative col-span-6">
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <CircleBackground color="#F65435" className="animate-spin-slower" />
        </div>
        <div className="relative z-10 mx-auto w-full rounded bg-white">
          <FeatureMedia feature={features[selectedIndex]} />
        </div>
      </div>
    </Tab.Group>
  );
}

function FeaturesMobile({ features }: { features: Feature[] }) {
  let [activeIndex, setActiveIndex] = useState(0);
  let slideContainerRef = useRef<HTMLDivElement>(null);
  let slideRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    let observer = new window.IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            setActiveIndex(slideRefs.current.indexOf(entry.target as never));
            break;
          }
        }
      },
      {
        root: slideContainerRef.current,
        threshold: 0.6,
      }
    );

    for (let slide of slideRefs.current) {
      if (slide) {
        observer.observe(slide);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [slideContainerRef, slideRefs]);

  return (
    <>
      <div
        ref={slideContainerRef}
        className="flex snap-x snap-mandatory overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&::-webkit-scrollbar]:hidden"
      >
        {features.map((feature, featureIndex) => (
          <div
            key={featureIndex}
            ref={(ref) => (slideRefs.current[featureIndex] = ref!)}
            className="w-full flex-none snap-center px-4 sm:px-6"
          >
            <div className="relative transform overflow-hidden rounded-2xl bg-gray-800 px-5 py-6">
              <div className="relative mx-auto w-full max-w-[366px]">
                <FeatureMedia feature={feature} />
              </div>
              <div className="inset-x-0 bottom-0 bg-gray-800/95 p-6 backdrop-blur sm:p-10">
                <DynamicHeroIcon
                  icon={feature.icon}
                  className="h-8 w-8 text-white"
                />
                <h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">
                  {feature.title}
                </h3>
                <p className="mt-2 text-sm text-gray-400">
                  {feature.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-center gap-3">
        {features.map((_, featureIndex) => (
          <button
            type="button"
            key={featureIndex}
            className={clsx(
              "relative h-0.5 w-4 rounded-full",
              featureIndex === activeIndex ? "bg-gray-300" : "bg-gray-500"
            )}
            aria-label={`Go to slide ${featureIndex + 1}`}
            onClick={() => {
              slideRefs.current[featureIndex].scrollIntoView({
                block: "nearest",
                inline: "nearest",
              });
            }}
          >
            <span className="absolute -inset-x-1.5 -inset-y-3" />
          </button>
        ))}
      </div>
    </>
  );
}

interface PrimaryFeaturesProps {
  features: Feature[];
}

const PrimaryFeatures: React.FC<PrimaryFeaturesProps> = ({ features }) => {
  return (
    <section
      id="features-cards"
      aria-label="Nuestras funcionalidades"
      className="bg-dark pb-20 sm:pb-36"
    >
      <div className="md:hidden">
        <FeaturesMobile features={features} />
      </div>
      <Container className="hidden md:block">
        <FeaturesDesktop features={features} />
      </Container>
    </section>
  );
};

export default PrimaryFeatures;
