import { RelatedMedia } from "@/types/Media";
import { getUploadUrl } from "@/utils/uploadUtils";
import Image from "next/image";
import Link from "next/link";
import { useId } from "react";
import Button from "src/components/atoms/Button";
import Container from "src/components/atoms/Container";

function BackgroundIllustration(props: any) {
  let id = useId();

  return (
    <div {...props}>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-slow"
      >
        <path
          d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M513 1025C230.23 1025 1 795.77 1 513"
          stroke={`url(#${id}-gradient-1)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-1`}
            x1="1"
            y1="513"
            x2="1"
            y2="1025"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F65435" />
            <stop offset="1" stopColor="#F65435" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
      >
        <path
          d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M913 513c0 220.914-179.086 400-400 400"
          stroke={`url(#${id}-gradient-2)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-2`}
            x1="913"
            y1="513"
            x2="913"
            y2="913"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F65435" />
            <stop offset="1" stopColor="#F65435" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

interface HeroProps {
  id: number;
  __component: string;
  header: string;
  subHeader: string;
  image: RelatedMedia;
  button: {
    label: string;
    url: string;
  };
}
const Hero: React.FC<HeroProps> = ({ header, subHeader, image, button }) => {
  return (
    <div className="overflow-hidden py-20 sm:py-32 lg:pb-32 xl:pb-36">
      <Container>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
          <div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
            <h1 className="text-4xl font-medium tracking-tight text-gray-900">
              {header}
            </h1>
            <p className="my-6 text-lg text-gray-600">{subHeader}</p>
            <div>
              <Link href={button.url} prefetch={false}>
                <Button type="button" size="xl" color="primary" variant="solid">
                  {button.label}
                </Button>
              </Link>
            </div>
          </div>
          <div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
            <BackgroundIllustration className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0" />
            <div className="relative flex items-center justify-center">
              <Image
                src={getUploadUrl(image.data.attributes)}
                width={720}
                height={720}
                alt={image.data.attributes.alternativeText}
                className=""
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
